<template>
  <div class="Details">
    <div class="bigImg"></div>
    <div class="detailTable">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 1130px;margin: 0 auto;"
        :header-cell-style="{textAlign: 'center',backgroundColor:'#e96761',color:'#fff'}"
        :header-row-style="{height:'80px'}"
        :cell-style="{ textAlign: 'center' }">
        <el-table-column
          prop="date"
          label=""
          min-width="20%">
        </el-table-column>
        <el-table-column
          prop="name"
          label="伦敦金"
          min-width="40%">
        </el-table-column>
        <el-table-column
          prop="address"
          label="伦敦银"
          min-width="40%">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Details',
    data () {
      return {
        tableData: [{
          date: '合约单位',
          name: '100',
          address: '5000'
        }, {
          date: '货币单位',
          name: 'USD',
          address: 'USD'
        },{
          date: '报价小数点',
          name: '2',
          address: '3'
        }, {
          date: '点差',
          name: '0.5美元',
          address: '0.04美元'
        }, {
          date: '单笔交易手数',
          name: '0.01手～30.00手',
          address: '0.01手～30.00手'
        },{
          date: '手数差值',
          name: '0.01手',
          address: '0.01手'
        }, {
          date: '最小挂单距离',
          name: '50',
          address: '50'
        },{
          date: '初始保证金',
          name: '1000.00美元/手',
          address: '1000.00美元/手'
        }, {
          date: '假期保证金',
          name: '双倍',
          address: '双倍'
        }, {
          date: '锁仓保证金',
          name: '500.00美元/手',
          address: '500.00美元/手'
        },{
          date: '库存费',
          name: '-1.5%/-0.75%',
          address: '-1.5%/-0.75%'
        },{
          date: '三天利息日',
          name: '周一',
          address: '周一'
        }, {
          date: '交易时间GMT+8',
          name: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45',
          address: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45'
        }, {
          date: '结算时间',
          name: '夏今：5点为结筤时间 \n 冬令：6点为结算时间',
          address: '夏今：5点为结筤时间 \n 冬令：6点为结算时间'
        }]
      }
    },
    created(){
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
    }
  }
</script>

<style lang="less" scoped>
  .Details{
    width: 100%;
    .bigImg{
      width: 100%;
      height: 500px;
      background: url('../../static/details/banner.png') no-repeat;
      background-size: cover;
    }
    .detailTable{
      width: 100%;
      box-sizing: border-box;
      padding: 105px 0px;
      margin: 0 auto;
      /deep/ .el-table__row{
        height: 89px;
      }
      /deep/ .el-table__row--striped{
        height: 80px;
      }
      /deep/ .el-table__row td:nth-child(1){
        font-weight: 600 !important;
      }
      /deep/ .el-table .cell {
        white-space: pre-line;
      }
      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td{
        background: #f4f4f9;
      }
    }
  }
</style>